/*
 * 업무구분: 수수료세부명세 세부팝업
 * 화 면 명: MSPAP137P
 * 화면설명: 세부팝업7(9컬럼)
 * 작 성 일: 2023.05
 * 작 성 자: 정지윤
 */
 <template>
  <ur-page-container class="msp" :title="title" :show-title="true" type="subpage" :topButton="true">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">
        <mo-list :list-data="dataList">
          <template #list-item="{item}">
            <mo-list-item expandable>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip">{{item.chnlId1}}</span> <!-- 설계사코드 -->
                </div>
                <div class="list-item__contents__info row">
                  <span class="fs14rem mr10">적용환수환산</span><span class="crTy-bk1 txtSkip maxW180 pr2">{{item.totCnvlPrfmAmt ? $bizUtil.numberWithCommas(item.totCnvlPrfmAmt) : '0'}} 원</span>
                </div>
                <div class="list-item__contents__info row">
                  <span class="fs14rem mr10">성과비례환수금액</span><span class="crTy-bk1 txtSkip maxW180 pr2">{{item.dueConamnt ? $bizUtil.numberWithCommas(item.dueConamnt) : '0'}} 원</span>
                </div>
              </div>
              <!-- 아코디언 컨텐츠 -->
              <template v-slot:expand>
                <div class="list-item-detail">
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">성명</span><span class="ml20 crTy-bk1">{{item.name}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">계약번호</span><span class="ml20 crTy-bk1">{{item.applnrTt}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">계약월</span><span class="ml20 crTy-bk1">{{$bizUtil.dateYyMmFormat(item.pymPlnYm)}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">당시슈퍼팀장</span><span class="ml20 crTy-bk1">{{item.chnlId}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">당시유지율</span><span class="ml20 crTy-bk1">{{parseFloat(item.remRat).toFixed(1)}}</span> <!-- 소수점 1자리까지만 표기 -->
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">당시지급률</span><span class="ml20 crTy-bk1">{{parseFloat(item.pymRat).toFixed(1)}}</span> <!-- 소수점 1자리까지만 표기 -->
                  </div>
                </div>
              </template>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP137P",
    screenId: "MSPAP137P",
    components: {},
    props: {
      // 선택항목
      pComsInqrItm: {
        type: Object,
        defalt: () => {}
      },
      // FC정보(FC코드, 마감년월)
      pInfo: {
        type: Object,
        defalt: () => {}
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_getList()
    },
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        title: this.pComsInqrItm.comsInqrItmNm.replace('-','').trim(),
        dataList: [],

        // index-scroll 초성스크롤 위치지정  스크롤에 따른 위치변경은 개발에서
        indexStyle: {
          top:'150px',
          bottom:'100px',
          height:'calc(100vh - 345px)',
          position:'fixed'
        },
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_getList
      * 설명       : 세부창 리스트 조회
      ******************************************************************************/
      fn_getList() {
        let lv_Vm = this
        let trnstId = 'txTSSAP14S1'  //F1ICA0019  슈퍼팀장 성과비례 환수
        let pParam = {
          chnlId: lv_Vm.pInfo.cnsltNo, // FC코드
          actulYm: lv_Vm.pInfo.finYm, // 마감년월
        }
        console.log('pParam >> ', pParam)

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if (lv_Vm.DEBUG) console.log(response)
          if ( response.body != null) {
            console.log(' 슈퍼팀장 성과비례 환수 test 조회 >>> ', response.body)
            if(response.body.aPSuperTmgrResuPrprRdmtmDtlSVO !== null){
              response.body.aPSuperTmgrResuPrprRdmtmDtlSVO.forEach(data => {
                let tmp = {}
                  tmp.chnlId1		       = data.chnlId1           //설계사코드
                  tmp.name			       = data.name              //성명
                  tmp.applnrTt	       = data.applnrTt          //계약번호
                  tmp.pymPlnYm	       = data.pymPlnYm          //계약월
                  tmp.totCnvlPrfmAmt	 = data.totCnvlPrfmAmt    //적용환수환산
                  tmp.chnlId	         = data.chnlId            //당시슈퍼팀장
                  tmp.remRat	         = data.remRat            //당시유지율
                  tmp.pymRat		       = data.pymRat            //당시지급율
                  tmp.dueConamnt       = data.dueConamnt        //성과비례환수금액
                  lv_Vm.dataList.push(tmp)
              })
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },

      /******************************************************************************
      * Function명 : fn_ClickBtnPrevious
      * 설명       : 
      ******************************************************************************/
      fn_ClickBtnPrevious () {this.$router.go(-1)},

    }
  };
</script>
<style scoped>
</style>