/*
 * 업무구분: 수수료세부명세 세부팝업
 * 화 면 명: MSPAP139P
 * 화면설명: 세부팝업9(7컬럼)
 * 작 성 일: 2024.01
 * 작 성 자: 송진의
 */
 <template>
  <ur-page-container class="msp" :title="title" :show-title="true" type="subpage" :topButton="true">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
        
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">
        <mo-list :list-data="dataList">
          <template #list-item="{item}">
            <mo-list-item expandable>
              <div class="list-item__contents">                
                <div class="list-item__contents__info row">
                  <span class="fs14rem mr10">증권번호</span><span class="crTy-bk1 txtSkip maxW180 pr2">{{item.contNo}}</span>
                </div>
                <div class="list-item__contents__info row">
                  <span class="fs14rem mr10">환수금액</span><span class="crTy-bk1 txtSkip maxW180 pr2">{{$bizUtil.numberWithCommas(item.rdmtnAmt)}} 원</span>
                </div>
              </div>
              <!-- 아코디언 컨텐츠 -->
              <template v-slot:expand>
                <div class="list-item-detail">
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">신계약수수료</span><span class="ml20 crTy-bk1">{{$bizUtil.numberWithCommas(item.ncontComs)}} 원</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">계약관리보너스</span><span class="ml20 crTy-bk1">{{$bizUtil.numberWithCommas(item.contMngBns)}} 원</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">신인수수료</span><span class="ml20 crTy-bk1">{{$bizUtil.numberWithCommas(item.nwmanComs)}} 원</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">성과보너스</span><span class="ml20 crTy-bk1">{{$bizUtil.numberWithCommas(item.resuComs)}} 원</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">조직수수료</span><span class="ml20 crTy-bk1">{{$bizUtil.numberWithCommas(item.orgComs)}} 원</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">기타수수료</span><span class="ml20 crTy-bk1">{{$bizUtil.numberWithCommas(item.etcComs)}} 원</span>
                  </div>
                </div>
              </template>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-add-area pt0 pb0">
        <ul class="terms-list-area crTy-bk7 fs14rem" style="padding-top:12px;">
          <li> 회차별 수수료 원단위 절상 계산 영향으로, 최종회차 지급時 원단위 금액이 조정 정산될 수 있습니다.</li>          
        </ul> 
      </ur-box-container>   
    </ur-box-container>    
  </ur-page-container>
  
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP139P",
    screenId: "MSPAP139P",
    components: {},
    props: {
      // 선택항목
      pItem: {
        type: Object,
        default: () => {}
      },
      // FC정보(FC코드, 마감년월)
      pInfo: {
        type: Object,
        defalt: () => {}
      }
    },
    
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.title = this.pItem && this.pItem.comsInqrItmNm && this.pItem.comsInqrItmNm !== '' ? this.pItem.comsInqrItmNm.replace('-','').trim() : '당월발생환수'
      this.fn_getList()
    },
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        title: '',
        dataList: []
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_getList
      * 설명       : 2차 세부 리스트 조회
      ******************************************************************************/
      fn_getList() {
        let isTest = false        
        if (isTest) {
          let mockData = this.fn_mockData()
          mockData.forEach(data => {
            let tmp = {}
              tmp.contNo				 = data.contNo					//증권번호
              tmp.ncontComs			 = data.ncontComs				//신계약수수료
              tmp.contMngBns		 = data.contMngBns			//계약관리보너스
              tmp.nwmanComs			 = data.nwmanComs				//신인수수료
              tmp.resuComs		   = data.resuComs			  //성과보너스
              tmp.orgComs	       = data.orgComs		    	//조직수수료
              tmp.etcComs	       = data.etcComs       	//기타수수료
              tmp.rdmtnAmt			 = data.rdmtnAmt				//환수금액
              this.dataList.push(tmp)
          })
          return
        }

        let lv_Vm = this
        let trnstId = 'txTSSAP14S3' //F1ICA0024 당월발생환수
        let pParam = {
          cnsltNo: lv_Vm.pInfo.cnsltNo, // FC코드
          finYm: lv_Vm.pInfo.finYm, // 마감년월          
        }
        console.log('pParam >> ', pParam)

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {          
          if ( response ) {
            if ( response.body ) {              
              let selThmmOccuRdmtnListVO = response.body.selThmmOccuRdmtnListVO
              if(selThmmOccuRdmtnListVO && selThmmOccuRdmtnListVO.length > 0 && selThmmOccuRdmtnListVO !== null){
                selThmmOccuRdmtnListVO.forEach(data => {
                  let tmp = {}
                    tmp.contNo				 = data.contNo					//증권번호
                    tmp.ncontComs			 = data.ncontComs				//신계약수수료
                    tmp.contMngBns		 = data.contMngBns			//계약관리보너스
                    tmp.nwmanComs			 = data.nwmanComs				//신인수수료
                    tmp.resuComs		   = data.resuComs			  //성과보너스
                    tmp.orgComs	       = data.orgComs		    	//조직수수료
                    tmp.etcComs	       = data.etcComs       	//기타수수료
                    tmp.rdmtnAmt			 = data.rdmtnAmt				//환수금액
                    lv_Vm.dataList.push(tmp)
                })
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '조회에 실패하였습니다. 다시 실행해주세요.')
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },

      /******************************************************************************
      * Function명 : 목업 데이터 조회
      * 설명       : PO 전 목업 데이터 활용
      ******************************************************************************/
      fn_mockData () {
        const selThmmOccuRdmtnListVO = [
            {
                "contNo": "",
                "ncontComs": "355",
                "contMngBns": "34500",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "34855"
            },
            {
                "contNo": "20000000000000001",
                "ncontComs": "0",
                "contMngBns": "250500",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "250500"
            },
            {
                "contNo": "29000001000014261",
                "ncontComs": "48000",
                "contMngBns": "336000",
                "nwmanComs": "10000",
                "resuComs": "10000",
                "orgComs": "10000",
                "etcComs": "10000",
                "rdmtnAmt": "424000"
            },
            {
                "contNo": "29000001000014268",
                "ncontComs": "84000",
                "contMngBns": "0",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "84000"
            },
            {
                "contNo": "29000001000014273",
                "ncontComs": "74400",
                "contMngBns": "0",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "74400"
            },
            {
                "contNo": "29000001000014523",
                "ncontComs": "132000",
                "contMngBns": "42000",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "174000"
            },
            {
                "contNo": "29000001000014531",
                "ncontComs": "0",
                "contMngBns": "123750",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "123750"
            },
            {
                "contNo": "29000001000014709",
                "ncontComs": "0",
                "contMngBns": "123750",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "123750"
            },
            {
                "contNo": "29000001000014713",
                "ncontComs": "0",
                "contMngBns": "123750",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "123750"
            },
            {
                "contNo": "29000001000014740",
                "ncontComs": "20000",
                "contMngBns": "0",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "20000"
            },
            {
                "contNo": "29000001000014748",
                "ncontComs": "12000",
                "contMngBns": "0",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "12000"
            },
            {
                "contNo": "29000001000014749",
                "ncontComs": "0",
                "contMngBns": "104500",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "104500"
            },
            {
                "contNo": "29000001000014752",
                "ncontComs": "0",
                "contMngBns": "25929",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "25929"
            },
            {
                "contNo": "29000001000014754",
                "ncontComs": "0",
                "contMngBns": "101750",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "101750"
            },
            {
                "contNo": "29000001000014761",
                "ncontComs": "0",
                "contMngBns": "330000",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "330000"
            },
            {
                "contNo": "29000001000014767",
                "ncontComs": "132000",
                "contMngBns": "501000",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "633000"
            },
            {
                "contNo": "29000001000014965",
                "ncontComs": "0",
                "contMngBns": "55000",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "55000"
            },
            {
                "contNo": "29000001000014966",
                "ncontComs": "0",
                "contMngBns": "55000",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "55000"
            },
            {
                "contNo": "29000001000014968",
                "ncontComs": "0",
                "contMngBns": "55000",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "55000"
            },
            {
                "contNo": "29000001000014982",
                "ncontComs": "0",
                "contMngBns": "13750",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "13750"
            },
            {
                "contNo": "29000001000014983",
                "ncontComs": "0",
                "contMngBns": "282550",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "282550"
            },
            {
                "contNo": "29000001000015029",
                "ncontComs": "110400",
                "contMngBns": "341850",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "452250"
            },
            {
                "contNo": "29000001000015031",
                "ncontComs": "0",
                "contMngBns": "268800",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "268800"
            },
            {
                "contNo": "29000001000015070",
                "ncontComs": "0",
                "contMngBns": "13750",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "13750"
            },
            {
                "contNo": "29000001000015072",
                "ncontComs": "60000",
                "contMngBns": "391000",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "451000"
            },
            {
                "contNo": "29000001000015128",
                "ncontComs": "33600",
                "contMngBns": "139300",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "172900"
            },
            {
                "contNo": "29000001000015130",
                "ncontComs": "145200",
                "contMngBns": "414550",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "559750"
            },
            {
                "contNo": "29000001000015190",
                "ncontComs": "132000",
                "contMngBns": "501000",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "633000"
            },
            {
                "contNo": "29000001000015213",
                "ncontComs": "150000",
                "contMngBns": "0",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "150000"
            },
            {
                "contNo": "29000001000015214",
                "ncontComs": "150000",
                "contMngBns": "0",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "150000"
            },
            {
                "contNo": "29000001000015215",
                "ncontComs": "27500",
                "contMngBns": "0",
                "nwmanComs": "0",
                "resuComs": "0",
                "orgComs": "0",
                "etcComs": "0",
                "rdmtnAmt": "27500"
            }
        ]
        return selThmmOccuRdmtnListVO
      }
    }
  };
</script>
<style scoped>
</style>