/*
 * 업무구분: 수수료세부명세 세부팝업
 * 화 면 명: MSPAP132P
 * 화면설명: 세부팝업2(6컬럼)
 * 작 성 일: 2023.05
 * 작 성 자: 정지윤
 */
 <template>
  <ur-page-container class="msp" :title="title" :show-title="true" type="subpage" :topButton="true">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">
        <mo-list :list-data="dataList">
          <template #list-item="{item}">
            <mo-list-item expandable>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip">{{item.prodTxt}}</span> <!-- 상품명 -->
                </div>
                <div class="list-item__contents__info row">
                  <span class="fs14rem mr10">월초보험료</span><span class="crTy-bk1 txtSkip maxW180 pr2">{{item.bomthPrm ? $commonUtil.numberWithCommas(item.bomthPrm) : '0'}} 원</span>
                </div>
                <div class="list-item__contents__info row">
                  <span class="fs14rem mr10">환산성적</span><span class="crTy-bk1 txtSkip maxW180 pr2">{{item.cnvlPrfmAmt ? $commonUtil.numberWithCommas(item.cnvlPrfmAmt) : '0'}} 원</span>
                </div>
              </div>
              <!-- 아코디언 컨텐츠 -->
              <template v-slot:expand>
                <div class="list-item-detail">
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">증권번호</span><span class="ml20 crTy-bk1">{{item.contNo}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">계약자</span><span class="ml20 crTy-bk1">{{item.polPolhldr}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">납입주기</span><span class="ml20 crTy-bk1">{{item.payrhyt}}</span>
                  </div>
                </div>
              </template>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>
    </ur-box-container>

    <!-- no-data --> 
    <!-- <ur-box-container v-if="dataList.length == 0" alignV="start" componentid="" direction="column" class="no-data">
      <mo-list-item ref="expItem">
        <div class="list-item__contents">
          <div class="list-item__contents__info middle-space">
            <span class="ns-ftcr-gray"><p class="mt10">조건에 일치하는 데이터가 없습니다.</p></span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container> -->

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP132P",
    screenId: "MSPAP132P",
    components: {
    },
    props: {
      // 선택항목
      pComsInqrItm: {
        type: Object,
        defalt: () => {}
      },
      // FC정보(FC코드, 마감년월)
      pInfo: {
        type: Object,
        defalt: () => {}
      }
    },

     // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_getList()
    },
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        title: this.pComsInqrItm.comsInqrItmNm.replace('-','').trim(),
        dataList: [],

        // index-scroll 초성스크롤 위치지정  스크롤에 따른 위치변경은 개발에서
        indexStyle: {
          top:'150px',
          bottom:'100px',
          height:'calc(100vh - 345px)',
          position:'fixed'
        },
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_getList
       * 설명       : 세부창 리스트 조회
      ******************************************************************************/
      fn_getList () {
        let lv_Vm = this
        let trnstId = ''

        if(lv_Vm.pComsInqrItm.comsInqrItmCd == 'N00K') {
          trnstId = 'txTSSAP13S2' //F1ICA0022    당월환산성적
        } else if(lv_Vm.pComsInqrItm.comsInqrItmCd == 'N00N') {
          trnstId = 'txTSSAP13S3'   //F1ICA0023   환수환산리스트 
        }
        let pParam = {
          cnsltNo: lv_Vm.pInfo.cnsltNo, // FC코드
          finYm: lv_Vm.pInfo.finYm, // 마감년월
        }
        console.log('pParam >> ', pParam)

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if (lv_Vm.DEBUG) console.log(response)
          if ( response.body != null) {
            if(trnstId=='txTSSAP13S2') {
              console.log('당월환산성적 test 조회 내역 >>> ', response.body)
              if(response.body.selThmnCnvlActulListVO !== null){
                response.body.selThmnCnvlActulListVO.forEach(data => {
                  let tmp = {}
                  tmp.bomthPrm = data.bomthPrm          //월초보험료
                  tmp.cnvlPrfmAmt = data.cnvlPrfmAmt		//환산성적
                  tmp.contNo = data.contNo			        //증권번호
                  tmp.payrhyt = data.payrhyt			      //납입주기
                  tmp.polPolhldr = data.polPolhldr		  //계약자
                  tmp.prodTxt = data.prodTxt			      //상품명1              
                  lv_Vm.dataList.push(tmp)
                })
              }
            }
            else {
              console.log('환수환산 test 조회 내역 >>> ', response.body)
              if(response.body.aPRdmtnCnvlDtlSVO !== null){
                response.body.aPRdmtnCnvlDtlSVO.forEach(data => {
                  let tmp = {}
                  tmp.bomthPrm = data.bomthPrm          //월초보험료
                  tmp.cnvlPrfmAmt = data.cnvlPrfmAmt		//환산성적
                  tmp.contNo = data.contNo			        //증권번호
                  tmp.payrhyt = data.payrhyt			      //납입주기
                  tmp.polPolhldr = data.polPolhldr		  //계약자
                  tmp.prodTxt = data.prodTxt			      //상품명1              
                  lv_Vm.dataList.push(tmp)
                })
              }
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },

      /******************************************************************************
       * Function명 : 
       * 설명       : 
      ******************************************************************************/
      fn_ClickBtnPrevious () {this.$router.go(-1)},
    }
  };
</script>
<style scoped>
</style>