/*
 * 업무구분: 수수료세부명세 세부팝업
 * 화 면 명: MSPAP135P
 * 화면설명: 세부팝업5(8컬럼)
 * 작 성 일: 2023.05
 * 작 성 자: 정지윤
 */
 <template>
  <ur-page-container class="msp" :title="title" :show-title="true" type="subpage" :topButton="true">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">
        <mo-list :list-data="dataList">
          <template #list-item="{item}">
            <mo-list-item expandable>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip">{{item.prodTxt}}</span> <!-- 상품명 -->
                </div>
                <div class="list-item__contents__info row">
                  <span class="fs14rem mr10">환산성적</span><span class="crTy-bk1 txtSkip maxW180 pr2">{{item.cnvlPrfmAmt ? $bizUtil.numberWithCommas(item.cnvlPrfmAmt) : '0'}} 원</span>
                </div>
                <div class="list-item__contents__info row">
                  <span class="fs14rem mr10">지급액</span><span class="crTy-bk1 txtSkip maxW180 pr2">{{item.ncontComs ? $bizUtil.numberWithCommas(item.ncontComs) : '0'}} 원</span>
                </div>
              </div>
              <!-- 아코디언 컨텐츠 -->
              <template v-slot:expand>
                <div class="list-item-detail">
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">증권번호</span><span class="ml20 crTy-bk1">{{item.contNo}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">청약일자</span><span class="ml20 crTy-bk1">{{$bizUtil.dateYyMmDdFormat(item.fstOfrYmd)}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">회차</span><span class="ml20 crTy-bk1">{{$bizUtil.lpad(String(item.ukepTms), 3, '0')}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">보증보험가입</span><span class="ml20 crTy-bk1">{{item.flfmGuaEntYN == 'X' ? 'Y' : 'N'}}</span> <!-- 'X'일 때는 Y로 표시, '공란'일 때는 N으로 표시 -->
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem min80">상품구분</span><span class="ml20 crTy-bk1">{{item.comsPrdgrCdNm}}</span>
                  </div>
                </div>
              </template>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP135P",
    screenId: "MSPAP135P",
    components: {},
    props: {
      // 선택항목
      pItem: {
        type: Object,
        default: () => {}
      },
      // FC정보(FC코드, 마감년월)
      pInfo: {
        type: Object,
        defalt: () => {}
      }
    },

    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_getList()
    },
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        title: this.pItem.comsInqrItmNm.replace('-','').trim(),
        dataList: [],

        // index-scroll 초성스크롤 위치지정  스크롤에 따른 위치변경은 개발에서
        indexStyle: {
          top:'150px',
          bottom:'100px',
          height:'calc(100vh - 345px)',
          position:'fixed'
        },
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_getList
      * 설명       : 2차 세부 리스트 조회
      ******************************************************************************/
      fn_getList() {
        let lv_Vm = this
        let trnstId = ''
        let pParam = {} 
        // 전속
        if(lv_Vm.pItem.comsInqrItmCd1=='RLAA' || lv_Vm.pItem.comsInqrItmCd=='SUM' ) { // RLAA:전속사번 수수료탭 신계약수수료/AFC사번 수수료탭 신계약수수료 초회분, SUM:신채널사번 수수료탭 신계약커미션 (확인필요)
          trnstId = 'txTSSAP13S5' //F1ICA0021 fC신계약상세
          pParam = {
            cnsltNo: lv_Vm.pInfo.cnsltNo, // FC코드
            finYm: lv_Vm.pInfo.finYm, // 마감년월
            comsInqrItmCd2: lv_Vm.pItem.comsInqrItmCd2 ? lv_Vm.pItem.comsInqrItmCd2 : lv_Vm.pItem.comsInqrItmCd // comsInqrItmCd 또는 comsInqrItmCd3 // 구분코드
          }
        } else {
          trnstId = 'txTSSAP14S2' //F1ICA0020 슈퍼팀장 성과비례급
          pParam = {
            chnlId: lv_Vm.pInfo.cnsltNo, // FC코드
            actulYm: lv_Vm.pInfo.finYm, // 마감년월
            comsInqrItmCd2: lv_Vm.pItem.comsInqrItmCd2 // 구분코드
          }
        }
        // 신채널 test
        // trnstId = 'txTSSAP13S5' // test 신채널신계약커미션
        // pParam = {
        //   cnsltNo: lv_Vm.pInfo.cnsltNo, 
        //   finYm: lv_Vm.pInfo.finYm, 
        //   comsInqrItmCd2: lv_Vm.pItem.comsInqrItmCd 
        // }
        // AFC


        console.log('pParam >> ', pParam)

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if (lv_Vm.DEBUG) console.log(response)
          if ( response.body != null) {
            console.log('MSPAP135P 2차세부팝업 조회 test >>> ', response.body)
            
            // 신계약수수료
            if(trnstId=='txTSSAP13S5'){
              if(response.body.aPSelContDtlDtlSVO !== null){
                response.body.aPSelContDtlDtlSVO.forEach(data => {
                  let tmp = {}
                  tmp.contNo				 = data.contNo					//증권번호
                  tmp.prodTxt				 = data.prodTxt					//상품명
                  tmp.fstOfrYmd			 = data.fstOfrYmd				//청약일자
                  tmp.ukepTms				 = data.ukepTms					//회차
                  tmp.cnvlPrfmAmt		 = data.cnvlPrfmAmt			//환산성적
                  tmp.flfmGuaEntYN	 = data.flfmGuaEntYN		//보증보험가입여부
                  tmp.comsPrdgrCdNm	 = data.comsPrdgrCdNm	  //상품구분
                  tmp.ncontComs			 = data.ncontComs				//지급액
                  lv_Vm.dataList.push(tmp)
                })
              }
            } else {// 그 외 수수료 등
              if(response.body.aPSuperTmgrResuPrprDtlSVO !== null){
                response.body.aPSuperTmgrResuPrprDtlSVO.forEach(data => {
                  let tmp = {}
                  tmp.contNo				 = data.contNo					//증권번호
                  tmp.prodTxt				 = data.prodTxt					//상품명
                  tmp.fstOfrYmd			 = data.fstOfrYmd				//청약일자
                  tmp.ukepTms				 = data.ukepTms					//회차
                  tmp.cnvlPrfmAmt		 = data.cnvlPrfmAmt			//환산성적
                  tmp.flfmGuaEntYN	 = data.flfmGuaEntYN		//보증보험가입여부
                  tmp.comsPrdgrCdNm	 = data.comsPrdgrCdNm	  //상품구분
                  tmp.ncontComs			 = data.ncontComs				//지급액
                  lv_Vm.dataList.push(tmp)
                })
              }
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },

      /******************************************************************************
      * Function명 : 
      * 설명       : 
      ******************************************************************************/
      fn_ClickBtnPrevious () {this.$router.go(-1)},
    }
  };
</script>
<style scoped>
</style>