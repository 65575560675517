/*
 * 업무구분: 수수료세부명세 세부팝업
 * 화 면 명: MSPAP142P
 * 화면설명: 세부팝업10(3컬럼)
 * 작 성 일: 2024.04
 * 작 성 자: 송진의
 */
 <template>
  <ur-page-container class="msp" :title="title" :show-title="true" type="subpage" :topButton="true">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">
        <mo-list :list-data="dataList">
          <template #list-item="{item}">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="fs14rem mr10 mt4">계약번호</span><span class="name txtSkip">{{item.contNo}}</span>
                </div>
                <div class="list-item__contents__info row">
                  <span class="fs14rem mr10">회차</span><span class="crTy-bk1 txtSkip maxW180 pr2">{{item.lstPadTms}}</span>
                </div>
                <div class="list-item__contents__info row">
                  <span class="fs14rem mr10">총 적립액</span><span class="crTy-bk1 txtSkip maxW180 pr2">{{item.sumPrm ? $bizUtil.numberWithCommas(item.sumPrm) : '0'}} 원</span>
                </div>
              </div>              
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP142P",
    screenId: "MSPAP142P",
    components: {},
    props: {
      // 선택항목
      pItem: {
        type: Object,
        default: () => {}
      },
      // FC정보(FC코드, 마감년월)
      pInfo: {
        type: Object,
        defalt: () => {}
      }
    },
    
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.title = this.pItem && this.pItem.comsInqrItmNm && this.pItem.comsInqrItmNm !== '' ? this.pItem.comsInqrItmNm.replace('-','').trim() : '보유계약리스트'
      this.fn_getList()
    },
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        title: '',
        dataList: []
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_getList
      * 설명       : 2차 세부 리스트 조회
      ******************************************************************************/
      fn_getList() {        
        let trnstId = 'txTSSAP14S4' //F1ICA0025 비즈쉐어 보유계약리스트
        let pParam = {
          cnsltNo: this.pInfo.cnsltNo, // FC코드
          finYm: this.pInfo.finYm, // 마감년월          
        }
        console.log('pParam >> ', pParam)
        this.post(this, pParam, trnstId, 'S').then( response => {          
          if ( response?.body ) {
              const selBizShareRetContListVO = response.body.selBizShareRetContListVO
              if(selBizShareRetContListVO && selBizShareRetContListVO.length > 0){
                selBizShareRetContListVO.forEach(data => {
                  let tmp = {}
                    tmp.contNo				 = data.contNo					//증권번호
                    tmp.lstPadTms			 = data.lstPadTms				//최종납입회차
                    tmp.sumPrm		     = data.sumPrm			    //입금보험료                    
                    this.dataList.push(tmp)
                })
              }
            } else {
              this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
    }
  };
</script>
<style scoped>
</style>