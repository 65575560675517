/*
 * 업무구분: 수수료 세부명세
 * 화 면 명: MSPAP130M
 * 작 성 일: 2023.05(상세팝업)
 * 작 성 자: 정지윤(상세팝업)
 */
 <template>
  <ur-page-container ref="page" class="msp" type="subpage" :show-title="false" title="수수료 세부명세" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <mo-button class="btn_popup_back" @click="fn_BackBtnClicked" />
              <div slot="nav" class="icon-wrapper" @click="fn_BackBtnClicked">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '수수료 세부명세' : '수수료 세부명세'}}</div>
              <div slot="action" v-if="isManager" @click="fn_OpenMSPAP106P"><mo-icon>search</mo-icon></div><!-- // 지점장이상 진입 시에만 노출 -->
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list pt10 bd-b-tys10">  
              <mo-list-item>
                <div v-if="fcInfo.name !== ''" class="list-item__contents pt5 pb10">
                  <div class="list-item__contents__title fexTy5 mb0">
                    <span class="name fs18rem fexInt mr4">{{fcInfo.name}}</span>
                    <mo-badge class="badge-box sm" :class="fn_getClass" text="" shape="status">{{fcInfo.grade}}</mo-badge>
                    <!-- 
                      class="lightyellow" : 30만 Club, 50만 Club, 70만 Club
                      class="lightgreen2" : 100만 Club, 150만 Club
                      class="lightblue3" : 명인, 명인P                      
                    -->
                  </div>
                  <div class="list-item__contents__info pb0">
                    <span>{{fcInfo.team}}</span><em>|</em><span>{{fcInfo.entrusting}}</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container> 
            <ur-box-container alignV="start" componentid="" direction="column" class="con-area row-box pt20"><!-- 레이블 과 한줄로 -->
              <span class="label-title w100px mb0">활동 월</span>
              <mo-month-picker class="full" v-model="targetMonth" @confirmed="fn_selectMonth" placeholder="월 조회" />
            </ur-box-container>
          </template>              

          <!-- stickey 영역 -->
          <template #sticky>   
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment con-area mt20 mb10">
              <mo-segment-wrapper solid primary v-model="tab_index">
                <mo-segment-button value="0">실적/효율</mo-segment-button>
                <mo-segment-button value="1">수수료</mo-segment-button>
                <mo-segment-button value="2">지급계</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
              <!-- AFC start -->
              <div v-if="fcInfo.team.substr(0,3)=='AFC'" class="gray-bar-block full fs16rem bdTN">
                <div class="gray-bar-li fexTy3" v-for="(item, index) in comsSpcf" :key="index">
                  <mo-button v-if="tab_index=='1'&&(isLink4.indexOf(item.comsInqrItmCd3) > -1 )" @click="fn_openPopup('1', item)" size="large" shape="borderless" class="crTy-blue4 pl0 pr0 hauto min60">{{item.comsInqrItmNm}}</mo-button> <!-- 신계약수수료 초회분, 계약관리보너스 -->
                  <mo-button v-else-if="tab_index=='1'&&item.linkSrnld!==' '" @click="fn_openPopup('4', item)" size="large" shape="borderless" class="crTy-blue4 pl0 pr0 hauto min60">{{item.comsInqrItmNm}}</mo-button> <!-- 성과보너스, 자격달성보너스 -->
                  <span v-else>{{item.comsInqrItmNm}}</span>
                  <span><b>{{item.comsInqrItmVal===null?'':$bizUtil.numberWithCommas(item.comsInqrItmVal)}}</b></span>
                </div>
              </div>
              <!-- AFC end -->
              <!-- 신채널 start -->
              <div v-else-if="fcInfo.team.substr(0,3)=='신채널'" class="gray-bar-block full fs16rem bdTN">
                <div class="gray-bar-li fexTy3" v-for="(item, index) in comsSpcf" :key="index">
                  <mo-button v-if="tab_index=='0'&&(isLink1.indexOf(item.comsInqrItmCd) > -1 )" @click="fn_openPopup('2', item)" size="large" shape="borderless" class="crTy-blue4 pl0 pr0 hauto min60">{{item.comsInqrItmNm}}</mo-button> <!-- 당월환산성적, 당월환수환산 -->
                  <mo-button v-else-if="tab_index=='1'&&item.linkSrnld=='PNLICC00015'" @click="fn_openPopup('5', item)" size="large" shape="borderless" class="crTy-blue4 pl0 pr0 hauto min60">{{item.comsInqrItmNm}}</mo-button> <!-- 신계약커미션 -->
                  <mo-button v-else-if="tab_index=='1'&&item.linkSrnld=='PNLICC00016'" @click="fn_openPopup('6', item)" size="large" shape="borderless" class="crTy-blue4 pl0 pr0 hauto min60">{{item.comsInqrItmNm}}</mo-button> <!-- 계약관리커미션 -->
                  <mo-button v-else-if="tab_index=='1'&&item.linkSrnld!==' '" @click="fn_openPopup('3', item)" size="large" shape="borderless" class="crTy-blue4 pl0 pr0 hauto min60">{{item.comsInqrItmNm}}</mo-button> <!-- 종신우대 보유계약관리보너스, 성과보너스, 보장우대분, 업적분-->
                  <span v-else>{{item.comsInqrItmNm}}</span>
                  <span><b>{{item.comsInqrItmVal===null?'':$bizUtil.numberWithCommas(item.comsInqrItmVal)}}</b></span>
                </div>
              </div>
              <!-- 신채널 end -->
              
              <!-- 전속FC start -->
              <div v-else class="gray-bar-block full fs16rem bdTN">
                <div class="gray-bar-li fexTy3" v-for="(item, index) in comsSpcf" :key="index">
                  <!-- 23.01월 마감부터 레이아웃 변경, 년도별 레이아웃 상이 -->
                  <mo-button v-if="tab_index=='0'&&(isLink1.indexOf(item.comsInqrItmCd) > -1)" @click="fn_openPopup('2', item)" size="large" shape="borderless" class="crTy-blue4 pl0 pr0 hauto min60">{{item.comsInqrItmNm}}</mo-button> <!-- 당월환산성적, 당월환수환산 -->
                  <mo-button v-else-if="tab_index=='1'&&(isLink2.indexOf(item.comsInqrItmCd3) > -1  &&  item.comsInqrItmCd!=='TITLE')" @click="fn_openPopup('1', item)" size="large" shape="borderless" class="crTy-blue4 pl0 pr0 hauto min60">{{item.comsInqrItmNm}}</mo-button> <!-- 신계약수수료, 계약관리보너스, 건강상해유지보너스, 성과보너스, 정착수수료, 정착수수료2(~22.1월위촉), 신인성과보너스(22.2월위촉~), 신인유지보너스, 조직명장수수료, RM수수료 -->
                  <mo-button v-else-if="tab_index=='1'&&item.linkSrnld!==' '" @click="fn_openPopup('7', item)" size="large" shape="borderless" class="crTy-blue4 pl0 pr0 hauto min60">{{item.comsInqrItmNm}}</mo-button> <!-- 슈퍼팀장수수료, 슈퍼팀장성과비례환수 -->
                  <mo-button v-else-if="tab_index=='2'&&(isLink3.indexOf(item.comsInqrItmCd) > -1)" @click="fn_openPopup('1', item)" size="large" shape="borderless" class="crTy-blue4 pl0 pr0 hauto min60">{{item.comsInqrItmNm}}</mo-button> <!-- 행복설계수수료, 급여이체보험료공제 -->
                  <span v-else>{{item.comsInqrItmNm}}</span>
                  <span><b>{{item.comsInqrItmVal===null?'':$bizUtil.numberWithCommas(item.comsInqrItmVal)}}</b></span>
                </div>
              <!-- 전속FC end -->
              </div>
            </ur-box-container>
            
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pt30">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-add-area pt0 pb0">
                <ul class="terms-list-area crTy-bk7 fs14rem">
                  <li>정기지급일 前 조회내용은 가정산 기준으로, 실제 최종 지급액과  다소 차이가 있을 수 있습니다.</li>
                  <li>비즈쉐어 전월누계액은 일부 만기경과건 포함되어 실제 적립액 누계액보다 과다계상되어 보여질 수 있습니다.</li>
                </ul> 
              </ur-box-container>               
            </ur-box-container>

            <!-- no-data --> 
            <ur-box-container v-if="fcInfo.name === ''" alignV="start" componentid="" direction="column" class="no-data">
              <mo-list-item ref="expItem">
                <div class="list-item__contents">
                  <div class="list-item__contents__info middle-space">
                    <span class="ns-ftcr-gray"><p class="mt10">상세조회 조건을 설정해 주세요</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
           
           
          </template>          
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import moment from 'moment'
  import Msg from '~/src/systems/webkit/msg/msg'
  import DateUtil from '@/ui/sp/common/exDateUtils'
  import MSPAP106P from '@/ui/ap/MSPAP106P'
  import MSPAP131P from '@/ui/ap/MSPAP131P'
  import MSPAP132P from '@/ui/ap/MSPAP132P'
  import MSPAP133P from '@/ui/ap/MSPAP133P'
  import MSPAP134P from '@/ui/ap/MSPAP134P'
  import MSPAP135P from '@/ui/ap/MSPAP135P'
  import MSPAP136P from '@/ui/ap/MSPAP136P'
  import MSPAP137P from '@/ui/ap/MSPAP137P'
  import MSPAP138P from '@/ui/ap/MSPAP138P'
  import MSPAP139P from '@/ui/ap/MSPAP139P'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP130M",
    screenId: "MSPAP130M",
    components: {
      MSPAP106P,
      MSPAP132P
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        tab_index: '', // tab contant 선택;
        cnsltNo: '',
        isManager: false, // 최초 영업관리자를 위한 조건값
        popupAP106: null,
        popup:'', // 상세팝업

        fcInfo: { // 설계사 정보
          name: '',
          grade: '',
          team: '',
          entrusting: ''
        },
        comsSpcf: [],
        comsSpcf0: [],  //실적
        comsSpcf1: [],  //수수료
        comsSpcf2: [],  //지급
        targetMonth: ['', ''], // 월 조회
        // isConfirm: false,
        // confirmMsg: '',

        // 링크걸기 (131p 오픈하는 항목(2차세부창 존재 항목)은 linkSrnld 값이 없음. 따라서 코드로 구분함)
        isLink1: 'N00K|N00N', // 전속사번 실적/효율탭 131p 오픈 (comsInqrItmCd3으로 구분)  N00K:'당월환산성적', N00N:'당월환수환산'
        isLink2: 'RLAA|RLAG|RLBS|RLBR|RLXL|RLYJ|RLBJ|RLBV|RLCE|RLJC|RLJR', // 전속사번 수수료탭 131p 오픈 (comsInqrItmCd3으로 구분)  RLAA:신계약수수료, RLAG:계약관리보너스, RLBS:건강상해유지보너스, RLBR:성과보너스, RLXL:정착보너스1, RLYJ:정착수수료, RLBJ:정착수수료2(~'22.1월위촉), RLBV:신인유지보너스, RLCE:신인성과보너스('22.2월위촉~), RLJC:조직명장 수수료, RLJR:RM 수수료
        isLink3: '6010260|D160', // 전속사번 지급계탭 131p 오픈 (comsInqrItmCd으로 구분)  6010260:행복설계수수료, D160:급여이체보험료공제
        isLink4: 'RLAA|RLAG'  // AFC사번 수수료탭 131p 오픈 (comsInqrItmCd3으로 구분)  RLAA:신계약수수료 초회분, RLAG:계약관리보너스 1차년
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      // Back Key 이벤트 바인딩
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)

      // 지점장 이상 권한인지 구분
      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        this.isManager = true
      } else {
        this.isManager = false
      }
    },
    mounted () {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

      // 조회 활동 월
      let month = DateUtil.fn_AddMonth(moment(new Date().toISOString()).format('YYYYMMDD'), -1)
      this.targetMonth = [month.substring(0,4), month.substring(4,6)]

      // 지점장 이상 권한 진입 시 컨설턴트 검색 팝업 노출
      if ( !this.isManager ) {
        this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
        this.fn_InquireComsSpcf()
      } else {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: "",
            content: '컨설턴트를 먼저 선택해 주세요.',
            title_pos_btn: "확인"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_AlertPop)
              this.fn_OpenMSPAP106P()
            },
            onPopupCancel: () => {
              this.$bottomModal.close(this.lv_AlertPop)
              this.fn_OpenMSPAP106P()
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
              this.fn_OpenMSPAP106P()
            }
          }
        })
      }
    },

    beforeDestroy() {
      // Back Key 이벤트 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },

    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    watch: {
      tab_index() {
        this.comsSpcf = this['comsSpcf' + this.tab_index]
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
      * Function명: fn_selectMonth
      * 설명: 활동 월 변경 시
      *********************************************************/
      fn_selectMonth(val) {
        if ( val !== null && val !== '' ) {
          this.fn_InquireComsSpcf()
        } else {
          this.getStore('confirm').dispatch('ADD', '활동 월을 입력하세요.')
        }
      },
      /*********************************************************
      * Function명: fn_InitData
      * 설명: 데이터 초기화
      *********************************************************/
      fn_InitData () {
        this.fcInfo.name = ''
        this.fcInfo.grade = ''
        this.fcInfo.team = ''
        this.fcInfo.entrusting = ''
        this.comsSpcf = []
        this.comsSpcf0 = []
        this.comsSpcf1 = []
        this.comsSpcf2 = []
      },
      /*********************************************************
      * Function명: fn_CheckData
      * 설명: 초기데이터는 전월로 활동월이 설정되며, 전월로 조회 시 리턴 되는
      * 제어일자를 이용하여 제어일자보다 작으면 조회안되도록 처리
      *********************************************************/
      fn_CheckData (param) {
        let befMon = moment(moment().subtract(1, 'month')).format('YYYYMM')
        let targetMonthStr = this.targetMonth[0] + this.$bizUtil.lpad(this.targetMonth[1], 2, '0')

        if ( befMon === targetMonthStr ) {
          let nowDay = new Date().getDate()

          if ( Number(nowDay) < Number(param)) {
            return false
          }
        }
        return true
      },
      /*********************************************************
      * Function명: fn_InquireComsSpcf
      * 설명: 수수료세부명세 조회
      *********************************************************/
      fn_InquireComsSpcf () {
        let lv_Vm = this
        let trnstId = 'txTSSAP00S2'

        let targetMonthStr = this.targetMonth[0] + this.$bizUtil.lpad(this.targetMonth[1], 2, '0')
        if ( targetMonthStr >= moment().format('YYYYMM')) {
          lv_Vm.fn_InitData()
          lv_Vm.getStore('confirm').dispatch('ADD', '활동 월을 확인하세요.')
          return
        }

        let pParam = {
          cnsltNo: lv_Vm.cnsltNo,
          finYm: targetMonthStr
        }

        lv_Vm.comsSpcf = []
        lv_Vm.comsSpcf0 = []
        lv_Vm.comsSpcf1 = []
        lv_Vm.comsSpcf2 = []

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if (lv_Vm.DEBUG) console.log(response)

          if ( response.body != null ) {
            if ( response.body.cntlDd !== null ) {
              if ( !lv_Vm.fn_CheckData(response.body.cntlDd )) {
                lv_Vm.fn_InitData()
                lv_Vm.getStore('confirm').dispatch('ADD', '당월 수수료는 ' + response.body.cntlDd + ' 일 부터 조회 가능합니다.')
                return
              }
            }
            lv_Vm.fcInfo.name = response.body.cnsltNm
            lv_Vm.fcInfo.grade = response.body.chnlQulfNm
            lv_Vm.fcInfo.team = response.body.onpstDofNm + ' ' + response.body.onpstTeamNm
            lv_Vm.fcInfo.entrusting = response.body.lpmfc === 0 ? '' : response.body.lpmfc + ' 차월'

            // Server 에서 체크
            // if ( response.body.aPActulSpcfItmList === null && response.body.aPComsSpcfItmList === null && response.body.aPPymSpcfItmList === null ) {
            //   lv_Vm.getStore('toast').dispatch('ADD', '해당 월에 수수료 세부 내역이 존재하지 않습니다.')
            // }

            // 실적
            if ( response.body.aPActulSpcfItmList != null && response.body.aPActulSpcfItmList.length > 0 ) {
              response.body.aPActulSpcfItmList.forEach((item) =>{
                if(!lv_Vm.$bizUtil.isEmpty(item.comsInqrItmNm)){
                  lv_Vm.comsSpcf0.push(item)
                }
              })
            }
            // 수수료
            if ( response.body.aPComsSpcfItmList != null && response.body.aPComsSpcfItmList.length > 0 ) {
              response.body.aPComsSpcfItmList.forEach((item) =>{
                if(!lv_Vm.$bizUtil.isEmpty(item.comsInqrItmNm)){
                  lv_Vm.comsSpcf1.push(item)
                }
              })
            }
            // 지급명세
            if ( response.body.aPPymSpcfItmList != null && response.body.aPPymSpcfItmList.length > 0 ) {
              response.body.aPPymSpcfItmList.forEach((item) =>{
                if(!lv_Vm.$bizUtil.isEmpty(item.comsInqrItmNm)){
                  lv_Vm.comsSpcf2.push(item)
                }
              })
            }

            lv_Vm.tab_index = '0'
            lv_Vm.comsSpcf = lv_Vm.comsSpcf0
          } else {
            lv_Vm.fn_InitData()
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
      },

      /******************************************************************************
      * Function명 : fn_OpenMSPAP106P
      * 설명       : 지점장 이상 권한 진입 시 컨설턴트 선택 Alert 노출
      ******************************************************************************/
      fn_OpenMSPAP106P(){
        const lp_Vm = this  
        this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
          properties: {
            pPage: 'MSPAP130M',
          },
          listeners: {
            onPopupSearch: (pData) => {
              console.log('onPopupSearch >> ' , pData)
              // 모달 닫기
              lp_Vm.$bottomModal.close(lp_Vm.popupAP106)

              if(pData != null && pData != undefined){
                lp_Vm.lv_FofCnslt = pData
                lp_Vm.cnsltNo = lp_Vm.lv_FofCnslt.key
                this.fn_InquireComsSpcf()
              }
            },
            onPopupClose: () => {
              console.log('onPopupClose')
              // 모달 닫기
              lp_Vm.$bottomModal.close(lp_Vm.popupAP106)
            }
          }
        })
      },

      /******************************************************************************
      * Function명 : fn_BackBtnClicked
      * 설명       : 뒤로가기 버튼(홈으로 이동)
      ******************************************************************************/
      fn_BackBtnClicked () {
        // if (this.$route.query?._isMenuItem) {
          this.$router.push({ name: 'MSPBC002M' })
        //   return
        // }
        // this.$router.go(-1)
      },

      /******************************************************************************
      * Function명 : fn_getClass
      * 설명       : FC자격
      ******************************************************************************/
      fn_getClass() {
        let rtn = 'lightyellow'
        if('명인|명인P'.indexOf(this.fcInfo.grade) > -1){
          rtn = 'lightblue3'
        }else if('슈퍼|프로'.indexOf(this.fcInfo.grade) > -1){
          rtn = 'lightgreen2'
        }
        return rtn
      },

      /******************************************************************************
      * Function명 : fn_openPopup
      * 설명       : 상세팝업 open
      ******************************************************************************/
      fn_openPopup(page, item) {         
        const lp_Vm = this  
        /* 
        finYm : 마감년월
        cnsltNo : FC코드
        pComsInqrItm, pItem : 선택항목
        */
        switch(page){
          case '1' : 
            lp_Vm.popup = lp_Vm.$bottomModal.open(MSPAP131P, { // 2컬럼 <!-- 급여이체보험료공제, FC당월활동실적조회 -->
              properties: {
                pInfo : {
                  finYm: lp_Vm.targetMonth[0] + lp_Vm.$bizUtil.lpad(lp_Vm.targetMonth[1], 2, '0'),
                  cnsltNo: lp_Vm.cnsltNo, 
                },
                pComsInqrItm : item 
              },
            })
            break
          case '2' : 
            lp_Vm.popup = lp_Vm.$bottomModal.open(MSPAP132P, { // 6컬럼 <!-- 당월환산성적, 환수환산리스트 -->
              properties: {
                pInfo : {
                  finYm: lp_Vm.targetMonth[0] + lp_Vm.$bizUtil.lpad(lp_Vm.targetMonth[1], 2, '0'), 
                  cnsltNo: lp_Vm.cnsltNo, 
                },
                pComsInqrItm : item 
              },
            })
            break
          case '3' : 
            lp_Vm.popup = lp_Vm.$bottomModal.open(MSPAP133P, { // 6컬럼 <!-- 신채널 종신우대, 성과보너스 --> 
              properties: {
                pInfo : {
                  finYm: lp_Vm.targetMonth[0] + lp_Vm.$bizUtil.lpad(lp_Vm.targetMonth[1], 2, '0'),
                  cnsltNo: lp_Vm.cnsltNo, 
                },
                pComsInqrItm : item 
              },
            })
            break
          case '4' : 
            lp_Vm.popup = lp_Vm.$bottomModal.open(MSPAP134P, { // 6컬럼 <!-- AFC 성과, 자격달성 보너스 -->
              properties: {
                pInfo : {
                  finYm: lp_Vm.targetMonth[0] + lp_Vm.$bizUtil.lpad(lp_Vm.targetMonth[1], 2, '0'), 
                  cnsltNo: lp_Vm.cnsltNo, 
                },
                pComsInqrItm : item  
              },
            })
            break
          case '5' : 
            lp_Vm.popup = lp_Vm.$bottomModal.open(MSPAP135P, { // 8컬럼 <!-- FC 신계약상세, 슈퍼팀장 성과비례급 -->
              properties: {
                pInfo : {
                  finYm: lp_Vm.targetMonth[0] + lp_Vm.$bizUtil.lpad(lp_Vm.targetMonth[1], 2, '0'),
                  cnsltNo: lp_Vm.cnsltNo,
                },
                pItem : item 
              }
            })
            break
          case '6' : 
            lp_Vm.popup = lp_Vm.$bottomModal.open(MSPAP136P, { // 8컬럼 <!-- FC 계약관리상세 --> 
              properties: {
                pInfo : {
                  finYm: lp_Vm.targetMonth[0] + lp_Vm.$bizUtil.lpad(lp_Vm.targetMonth[1], 2, '0'), 
                  cnsltNo: lp_Vm.cnsltNo,
                },
                pItem : item 
              },
            })
            break
          case '7' :
            let openPage = ''
            // if(item.comsInqrItmCd === '4070150') {  // SUM -> 4070150
            if(item.linkSrnld === 'PNLICC00039') {  // 코드 변경 작업이 있을 수 있어 코드로 구분 X
              openPage = MSPAP138P  // 14컬럼 <!-- 슈퍼팀장수수료 -->
            } else if(item.linkSrnld === 'PNLICC00044') {
              openPage = MSPAP139P  // 당월발생환수
            } else {
              openPage = MSPAP137P  // 9컬럼 <!-- 슈퍼팀장 성과비례환수(~'20.12월계약) -->
            }
            lp_Vm.popup = lp_Vm.$bottomModal.open(openPage, { 
              properties: {
                pInfo : {
                  finYm: lp_Vm.targetMonth[0] + lp_Vm.$bizUtil.lpad(lp_Vm.targetMonth[1], 2, '0'),
                  cnsltNo: lp_Vm.cnsltNo, 
                },
                pComsInqrItm : item 
              },
            })
            break
        }        
      },


    }
  };
</script>
<style scoped>
</style>