/*
 * 업무구분: 수수료세부명세 세부팝업
 * 화 면 명: MSPAP131P
 * 화면설명: 세부팝업1(2컬럼)
 * 작 성 일: 2023.05
 * 작 성 자: 정지윤
 */
 <template>
  <ur-page-container class="msp" :show-title="true" :title="title" type="subpage" :topButton="false">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="con-area" >
        <!-- 급여이체보험료공제 start -->
        <div v-if="pComsInqrItm.comsInqrItmCd === 'D160'" class="gray-bar-block full fs16rem bdTN">
          <div class="gray-bar-li fexTy3" v-for="(item, index) in dtlList" :key="index">
            <!-- mo-button shape="borderless" size="large" class="crTy-bk1 pl0 pr0 hauto min60">{{item.applnrTt}}</mo-button--> <!-- 증권번호 --> <!-- crTy-blue4 -->
            <span class="crTy-bk1 pl0 pr0 hauto min60">{{item.applnrTt}}</span> <!-- 증권번호 --> <!-- crTy-blue4 -->
            <span><b>{{item.deductAmt ? $bizUtil.numberWithCommas(item.deductAmt) : '0'}}</b></span>  <!-- 공제액 -->
          </div>
        </div>
        <!-- 급여이체보험료공제 end -->
        <!-- 그 외 start -->
        <div v-else class="gray-bar-block full fs16rem bdTN">
          <div class="gray-bar-li fexTy3" v-for="(item, index) in dtlList" :key="index">
            <mo-button v-if="item.srnSrnSlctPsbYN!=''" shape="borderless" size="large" class="crTy-blue4 pl0 pr0 hauto min60" @click="fn_open2Popup(item)">{{item.comsInqrItmNm}}</mo-button>
            <mo-button v-else size="large" shape="borderless" class="crTy-bk1 pl0 pr0 hauto min60">{{item.comsInqrItmNm}}</mo-button>
            <span><b>{{item.itmVal ? $bizUtil.numberWithCommas(item.itmVal) : '0'}}</b></span>
          </div>
        </div>
        <!-- 그 외 end -->
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPAP135P from '@/ui/ap/MSPAP135P'
  import MSPAP136P from '@/ui/ap/MSPAP136P'
  import MSPAP142P from '@/ui/ap/MSPAP142P'
  import MSPAP143P from '@/ui/ap/MSPAP143P'
  import MSPAP144P from '@/ui/ap/MSPAP144P'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP131P",
    screenId: "MSPAP131P",
    components: {},
    props: {
      // 선택항목
      pComsInqrItm: {
        type: Object,
        defalt: () => {}
      },
      // FC정보(FC코드, 마감년월)
      pInfo: {
        type: Object,
        defalt: () => {}
      }
    },

    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      console.log('수수료탭 item >>> ',this.pComsInqrItm)      
      this.fn_getList(this.pComsInqrItm)
    },
    mounted () {
     // 스크롤 애니메이션 instance 선언
      // this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      // document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        popup:'',
        title: this.pComsInqrItm.comsInqrItmNm.replace('-','').trim(), // 팝업 타이틀
        dtlList: [], // 출력 항목 리스트
        lv_bizShareItmCd: '6010260'
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
     /******************************************************************************
      * Function명 : fn_getList
      * 설명       : 1차 세부창 리스트 조회
      ******************************************************************************/
      fn_getList() {        
        let trnstId = ''
        let pParam = {}
        /* 
        finYm, actulYm : 마감년월
        cnsltNo, chnlId : FC코드
        comsInqrItmCd : 구분코드
        */
        if(this.pComsInqrItm.comsInqrItmCd === 'D160'){
          trnstId = 'txTSSAP13S8' // F1ICA0017 급여이체보험료공제
          pParam = {
            chnlId: this.pInfo.cnsltNo,
            actulYm: this.pInfo.finYm
          }
        }
        else {
          trnstId = 'txTSSAP13S1'  //C341_F1ICA0012_S FC당월활동실적조회 (비즈쉐어 포함)
          pParam = {
            cnsltNo: this.pInfo.cnsltNo,
            finYm: this.pInfo.finYm,
            comsInqrItmCd: this.pComsInqrItm.comsInqrItmCd3
          }
        }
        console.log('pParam >> ', pParam)
        console.log('trnstId >> ', trnstId)
        

        this.post(this, pParam, trnstId, 'S').then( response => {
          if (this.DEBUG) console.log(response)
          if ( response?.body ) {
            if(trnstId == 'txTSSAP13S8') {
              console.log('급여이체보험료공제 test 조회 >>> ', response.body)
              this.dtlList = response.body.aPSelSaryTrsfPrmSbtrDtlSVO
            } else {
              console.log('FC당월활동실적조회 test 조회 >>> ', response.body)
              this.dtlList = response.body.dtlListVO
              return false
              // 비즈쉐어 일때, 당월적립액, 띄어쓰기
              if (response?.body?.comsInqrItmCd && response.body.comsInqrItmCd === this.lv_bizShareItmCd) {
                console.log('biz share checked')
                this.dtlList.forEach(item => {
                  if (item?.comsInqrItmNm && (item.comsInqrItmNm.indexOf('전월 누계액') > -1 || item.comsInqrItmNm.indexOf('당월 적립액') > -1)) {
                    item.comsInqrItmNm = ' ' + item.comsInqrItmNm.trim()
                    console.log('item.comsInqrItmNm >', item.comsInqrItmNm)
                  }
                })
              }              
            }
          } else {
            if (response?.msgComm?.msgDesc) {
              this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },

     /******************************************************************************
      * Function명 : fn_open2Popup
      * 설명       : 2차 세부창 팝업
      ******************************************************************************/
      fn_open2Popup(item) {        
        let openPage = ''
        if (item.comsInqrItmCd1 === 'RLAG'){ // 계약관리보너스
          openPage = MSPAP136P // 8컬럼 <!-- FC 계약관리상세 -->
        } else if (item.comsInqrItmCd1 === this.lv_bizShareItmCd && item.linkSrnld === 'PNLICC00041'){ // 비즈쉐어 보유계약리스트
          openPage = MSPAP142P // 3컬럼 <!-- 비즈쉐어 보유계약리스트 -->
        } else if (item.comsInqrItmCd1 === this.lv_bizShareItmCd && item.linkSrnld === 'PNLICC00042'){ // 비즈쉐어 유지환산리스트
          openPage = MSPAP143P // 3컬럼 <!-- 비즈쉐어 유지환산리스트 -->
        } else if (item.comsInqrItmCd1 === this.lv_bizShareItmCd && item.linkSrnld === 'PNLICC00043'){ // 비즈쉐어 중도혜약계약리스트
          openPage = MSPAP144P // 2컬럼 <!-- 비즈쉐어 중도혜약계약리스트 -->
        } else {
          openPage = MSPAP135P // 8컬럼 <!-- FC 신계약상세, 슈퍼팀장 성과비례급 -->
        }
        this.popup = this.$bottomModal.open(openPage, {
          properties: {
            pInfo : {
              cnsltNo: this.pInfo.cnsltNo,
              finYm: this.pInfo.finYm,
              },
            pItem: item // 2차세부팝업
          },
          listeners: {
          }
        })
      },
    }
  };
</script>
<style scoped>
</style>