/*
 * 업무구분: 수수료세부명세 세부팝업
 * 화 면 명: MSPAP143P
 * 화면설명: 세부팝업11(3컬럼)
 * 작 성 일: 2024.04
 * 작 성 자: 송진의
 */
 <template>
  <ur-page-container class="msp" :title="title" :show-title="true" type="subpage" :topButton="true">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">
        <mo-list :list-data="dataList">
          <template #list-item="{item, index}">
            <mo-list-item>
              <div class="list-item__contents" v-if="index === 0">
                <div class="list-item__contents__title">
                  <span class="fs14rem mr10 mt4">지급율</span><span class="name">{{item.rate}} %</span>
                </div>
                <div class="list-item__contents__title">
                  <span class="fs14rem mr10 mt4">지급액</span><span class="name">{{$bizUtil.numberWithCommas(item.pymAmt)}} 원</span>
                </div>                
              </div>
              <div class="list-item__contents" v-else>
                <div class="list-item__contents__info row">                  
                  <span class="fs14rem mr10">기준월</span><span class="crTy-bk1 maxW180 pr2">{{item.actulYm}}</span>
                </div>
                <div class="list-item__contents__info row">
                  <span class="fs14rem mr10">회차</span><span class="crTy-bk1 maxW180 pr2">{{$bizUtil.lpad(String(item.ukepTms), 3, '0')}}</span>
                </div>
                <div class="list-item__contents__info row">
                  <span class="fs14rem mr10">환산</span><span class="crTy-bk1 maxW180 pr2">{{item.prm ? $bizUtil.numberWithCommas(item.prm) : '0'}} 원</span>
                </div>
              </div>              
            </mo-list-item>            
          </template>
        </mo-list>   
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP143P",
    screenId: "MSPAP143P",
    components: {},
    props: {
      // 선택항목
      pItem: {
        type: Object,
        default: () => {}
      },
      // FC정보(FC코드, 마감년월)
      pInfo: {
        type: Object,
        defalt: () => {}
      }
    },
    
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.title = this.pItem && this.pItem.comsInqrItmNm && this.pItem.comsInqrItmNm !== '' ? this.pItem.comsInqrItmNm.replace('-','').trim() : '유지환산리스트'
      this.fn_getList()
    },
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        title: '',
        dataList: [],
        lv_rate: '',    // 지급율
        lv_pymAmt: ''   // 지급액
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_getList
      * 설명       : 2차 세부 리스트 조회
      ******************************************************************************/
      fn_getList() {        
        let trnstId = 'txTSSAP14S5' //F1ICA0026 비즈쉐어 당월발생액 세부
        let pParam = {
          cnsltNo: this.pInfo.cnsltNo, // FC코드
          finYm: this.pInfo.finYm, // 마감년월          
        }
        console.log('pParam >> ', pParam)
        this.post(this, pParam, trnstId, 'S').then( response => {
          if ( response?.body ) {
              this.dataList = []
              let tmp = {}
              if (response?.body?.rate && response?.body?.pymAmt) {
                tmp = {}
                tmp.rate           = response.body.rate	  //지급율
                tmp.pymAmt         = response.body.pymAmt	//지급액
                this.dataList.push(tmp)
              }

              const selBizShareThmmOccuAmtDtlSVO = response.body.selBizShareThmmOccuAmtDtlSVO
              if(selBizShareThmmOccuAmtDtlSVO && selBizShareThmmOccuAmtDtlSVO.length > 0){
                selBizShareThmmOccuAmtDtlSVO.forEach(data => {
                    tmp = {}
                    tmp.actulYm				 = data.actulYm				//실적년월
                    tmp.ukepTms			   = data.ukepTms				//회차
                    tmp.prm		         = data.prm			      //환산
                    this.dataList.push(tmp)
                })
              }
            } else {
              this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
    }
  };
</script>
<style scoped>
</style>